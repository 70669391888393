/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useState, setState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

import 'styles/magazinepage.scss'

// Components
import Layout from 'components/Layout'
import { BannerDefault, ButtonAlt } from 'components/Elements'
import { graphql, useStaticQuery } from 'gatsby'
import Content from 'components/Content'
import GravityForm from 'components/GravityForm'
import SEO from 'components/SEO'
import FsLightbox from 'fslightbox-react'
import Lightbox from 'react-image-lightbox'


const Magazine = () => {
  const { magazine } = useStaticQuery(graphql`
    {
      magazine: wordpressPage(wordpress_id: {eq: 1020}) {
        acf {
          banner {
            title
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          form {
            description
            gravity_form_id
          }
          description
          gallery {
            images {
              id: wordpress_id
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 850) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const images = magazine.acf.gallery.images.map((image) => image.localFile.childImageSharp.fluid.src)

  return (
    <Layout>
      <SEO yoast={magazine.yoast_meta} />
      <div className="magazine">
        <BackgroundImage loading="eager" alt="" className="over-ons-image" fluid={magazine.acf.banner.image.localFile.childImageSharp.fluid} />
        <BannerDefault className="color-background-main py-4">
          <h1 className="w-100 text-center font-size-xl font-weight-xl mb-0">{magazine.acf.banner.title}</h1>
        </BannerDefault>

        <section>
          <div className="container pb-5">
            <div className="row py-5 px-3 px-lg-5">
              <div className="col-lg-6 first p-4 p-lg-5 order-2 order-lg-1">
                <Content content={magazine.acf.form.description} className="mb-4 color-text-contrast font-size-xm font-weight-l" />
                <GravityForm id={4} />
              </div>
              <div className="col-lg-6 pl-lg-5 pt-lg-5 pb-3 pb-lg-0 order-1 order-lg-2">
                <Content content={magazine.acf.description} />
                <div className="d-flex flex-wrap">
                  {magazine.acf.gallery.images.map((image) => {

                    return (
                      <div key={image.id} className="magazine-image col-6 mb-4" onClick={() => setIsOpen(!isOpen)} role="button">
                        <Img loading="eager" alt="" fluid={image.localFile.childImageSharp.fluid} />
                      </div>
                    )
                  })}
                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() => (
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                      )}
                      onMoveNextRequest={() => (
                        setPhotoIndex((photoIndex + 1) % images.length)
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Magazine